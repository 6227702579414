import React, { useState, useEffect, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

import classes from './Slider.scss';
import Controls from './Controls';
import Dots from './Dots';

function Slider({ children: childrenProp }) {
  const [index, setIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const children = childrenProp.length ? childrenProp : [childrenProp];
  const { length } = children;

  function next() {
    setReverse(false);
    setIndex(index === length - 1 ? 0 : index + 1);
  }

  function previous() {
    setReverse(true);
    setIndex(index === 0 ? length - 1 : index - 1);
  }

  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: previous,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, 8000);

    return () => clearInterval(interval);
  }, [index]);

  function processChild(child, key) {
    function getInactive() {
      if (reverse) {
        return key === index + 1 || (index === length - 1 && key === 0);
      }

      return key === index - 1 || (index === 0 && key === length - 1);
    }

    return cloneElement(child, {
      key,
      active: key === index,
      inactive: getInactive(),
      reverse,
    });
  }

  return (
    <div className={classes.root} {...handlers}>
      <div className={classes.slides}>
        {children.map((child, key) => processChild(child, key))}
      </div>

      <Controls onClickNext={next} onClickPrevious={previous} />
      <Dots count={length} index={index} setIndex={setIndex} />
    </div>
  );
}

Slider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Slider;
