import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Image from 'components/Image';

import classes from './Slide.scss';

function Slide({ image, alt, active, inactive, reverse, children }) {
  const className = classNames({
    [classes.root]: true,
    [classes.active]: active,
    [classes.inactive]: inactive,
    [classes.reverse]: reverse,
    [classes['has-image']]: image,
  });

  return (
    <div className={className}>
      <div className={classes.content}>{children}</div>

      {image && <Image className={classes.image} image={image} alt={alt} />}
    </div>
  );
}

Slide.propTypes = {
  image: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  alt: PropTypes.string,
  active: PropTypes.bool,
  inactive: PropTypes.bool,
  reverse: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Slide.defaultProps = {
  image: null,
  alt: null,
  active: false,
  inactive: false,
  reverse: false,
};

export default Slide;
