import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Dots.scss';

function Dot({ active, onClick }) {
  const className = classNames({
    [classes.dot]: true,
    [classes.active]: active,
  });

  return (
    <button
      type="button"
      className={className}
      onClick={onClick}
      aria-label="Change slide"
    />
  );
}

Dot.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Dots({ count, index, setIndex }) {
  return (
    <div className={classes.root}>
      {[...Array(count)].map((_, key) => (
        <Dot key={key} active={key === index} onClick={() => setIndex(key)} />
      ))}
    </div>
  );
}

Dots.propTypes = {
  count: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  setIndex: PropTypes.func.isRequired,
};

export default Dots;
