import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Text from 'components/Text';
import Image from 'components/Image';

import classes from './Quote.scss';

function Quote({
  className: classNameProp,
  author,
  position,
  small,
  image,
  alt,
  children,
}) {
  const className = classNames(classNameProp, {
    [classes.root]: true,
    [classes.small]: small,
  });

  return (
    <div className={className}>
      <Text size="lg" color="gray-dark" weight="semi" spacing="md">
        {children}
      </Text>

      <div className={classes.tail}>
        <Text>
          <Text weight="semi" inline>
            - {author}
          </Text>

          {position && `, ${position}`}
        </Text>

        {image && <Image className={classes.image} image={image} alt={alt} />}
      </div>
    </div>
  );
}

Quote.propTypes = {
  className: PropTypes.string,
  author: PropTypes.string.isRequired,
  position: PropTypes.string,
  small: PropTypes.bool,
  image: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  alt: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Quote.defaultProps = {
  className: null,
  position: null,
  small: false,
  image: null,
  alt: null,
};

export default Quote;
