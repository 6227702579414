import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import homeImage from 'assets/images/pages/home/1.jpg';
import webshopOntwerpImage from 'assets/images/pages/diensten_webshopontwerp/1.jpg';
import onsWerkImage from 'assets/images/pages/onswerk/1.jpg';
import onlineMarketingImage from 'assets/images/pages/diensten_onlinemarketing/1.jpg';

import HomeTitle from 'blocks/HomeTitle';
import Content from 'blocks/Content';
import Grid from 'blocks/Grid';
import Slider, { Slide } from 'blocks/Slider';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import ImageBlock from 'components/ImageBlock';
import Text from 'components/Text';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import Card from 'components/Card';
import Quote from 'components/Quote';

const Home = ({ data }) => {
  const blogItems = data.allBlog.nodes;
  const quoteItems = data.allQuotes.nodes;
  const slideItems = data.allSlides.nodes;

  return (
    <Layout transparentHeader>
      <Seo image={{ src: homeImage }} />

      <HomeTitle image={{ src: homeImage }} alt="Welkom bij Batao">
        <Content align="left">
          <Text weight="semi" color="primary-lighter">
            Welkom bij Batao
          </Text>

          <Heading size="xxl" as="h1" color="white">
            Magento developers die retegoede webshops bouwen
          </Heading>

          <Text color="white" spacing="lg">
            Batao staat voor kwaliteit en aandacht. Wij ontwerpen, maken en
            vermarkten top-notch Magento webshops. Een goede klik en ditto
            samenwerking vinden wij daarbij superbelangrijk. Maak direct kennis
            om te kijken of die klik er is!
          </Text>

          <ButtonGroup>
            <Button color="tertiary" to="/contact">
              Neem contact met ons op
            </Button>

            <Button color="white" outline to="/ons-werk">
              Laat ons werk voor zich spreken
            </Button>
          </ButtonGroup>
        </Content>
      </HomeTitle>

      <Section>
        <Content>
          <Heading size="xl" as="h2">
            Tijd voor een professionelere of geheel nieuwe webshop?
          </Heading>

          <Text>
            Batao heeft meer dan 15 jaar ervaring in het maken van B2B en B2C
            webshops. Met een team van de allerbeste ontwerpers, developers en
            online marketeers zijn we in staat je een full service E-commerce
            dienst te leveren.
          </Text>
        </Content>

        <Grid>
          <ImageBlock
            title="Vormgevende kunstenaars"
            image={{ src: webshopOntwerpImage }}
            to="/diensten/webshop-ontwerp"
          >
            <Text color="white" spacing="lg">
              Een goede webshop begint bij een goed ontwerp. Elkaar goed leren
              kennen is dan ook prioriteit nummer 1. Pas als we jouw merk goed
              kennen, kunnen we iets maken dat past bij je merk.
            </Text>

            <Button as="span" color="white" hoverColor="secondary" outline>
              Bekijk onze ontwerpdiensten
            </Button>
          </ImageBlock>

          <ImageBlock
            title="Alles oplossende ontwikkelaars"
            image={{ src: onsWerkImage }}
            to="/ons-werk"
          >
            <Text color="white" spacing="lg">
              Programmeren is puzzeltjes oplossen, en toevallig hebben wij de
              beste puzzelaars in huis. Alleen wat uitmuntend is, is voor onze
              developers goed genoeg om het web op te schieten.
            </Text>

            <Button as="span" color="white" hoverColor="secondary" outline>
              Bekijk ons werk
            </Button>
          </ImageBlock>

          <ImageBlock
            title="Conversiegerichte online marketeers"
            image={{ src: onlineMarketingImage }}
            to="/diensten/webshop-onderhoud/online-marketing"
          >
            <Text color="white" spacing="lg">
              Ook hulp nodig bij het vermarkten van je producten? Onze online
              marketeers helpen je bij het verhogen van verkeer, conversie en
              omzet. Wil je weten wat ze voor jou kunnen betekenen?
            </Text>

            <Button as="span" color="white" hoverColor="secondary" outline>
              Bekijk onze marketingdiensten
            </Button>
          </ImageBlock>
        </Grid>
      </Section>

      <Section color="secondary-pastel" pattern>
        <Content>
          <Heading size="xl" as="h2">
            Wij leveren kwaliteit; maar laten dat liever over aan onze klanten
          </Heading>
        </Content>

        <Slider>
          {slideItems.map((item) => (
            <Slide key={item._id} image={item.image} alt={item.title}>
              <Heading>{item.title}</Heading>

              <Text spacing="lg">{item.text}</Text>

              <Button color="tertiary" to={item.link}>
                Bekijk case study
              </Button>
            </Slide>
          ))}
        </Slider>
      </Section>

      <Section>
        <Content>
          <Heading size="xl" as="h2">
            Klanten waar we trots op zijn
          </Heading>
        </Content>

        <Slider>
          {quoteItems.map((item) => (
            <Slide key={item._id}>
              <Content wide>
                <Quote
                  author={item.author}
                  position={item.position}
                  alt={item.title}
                >
                  “{item.quote}”
                </Quote>
              </Content>
            </Slide>
          ))}
        </Slider>
      </Section>

      <Section color="secondary-pastel" position="left" pattern>
        <Content>
          <Heading size="xl" as="h2">
            Batao: altijd bezig met nieuwe dingen
          </Heading>
        </Content>

        <Grid>
          {blogItems.map((item) => (
            <Card key={item._id} image={item.image} alt={item.title}>
              <Heading color="primary-light">{item.title}</Heading>

              <Text spacing="lg">{item.description}</Text>

              <Button color="primary-light" outline to={`/blog/${item.slug}`}>
                Lees verder
              </Button>
            </Card>
          ))}
        </Grid>

        <Content>
          <Button color="tertiary" to="/blog">
            Lees alle blogs
          </Button>
        </Content>
      </Section>

      <ContactCta />
    </Layout>
  );
};

Home.propTypes = {
  data: PropTypes.shape({
    allBlog: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          image: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        }),
      ).isRequired,
    }).isRequired,
    allQuotes: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          author: PropTypes.string.isRequired,
          position: PropTypes.string.isRequired,
          quote: PropTypes.string.isRequired,
          logo: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        }),
      ).isRequired,
    }).isRequired,
    allSlides: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
          image: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query home {
    allBlog(limit: 3, sort: { order: DESC, fields: _created }) {
      nodes {
        _id
        slug
        title
        description
        image {
          sharpImage: childImageSharp {
            fixed(width: 450, height: 250) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
          publicURL
        }
      }
    }
    allQuotes {
      nodes {
        _id
        author
        position
        quote
        logo {
          sharpImage: childImageSharp {
            fixed(width: 140) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
          publicURL
        }
      }
    }
    allSlides {
      nodes {
        _id
        title
        text
        link
        image {
          sharpImage: childImageSharp {
            fixed(width: 550, height: 430) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
          publicURL
        }
      }
    }
  }
`;

export default Home;
