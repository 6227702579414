import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import classes from './Controls.scss';

function Control({ onClick, icon }) {
  return (
    <button className={classes.control} onClick={onClick}>
      <Icon name={icon} />
    </button>
  );
}

Control.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Controls({ onClickNext, onClickPrevious }) {
  return (
    <div className={classes.root}>
      <Control icon="arrow-left" onClick={onClickPrevious} />
      <Control icon="arrow-right" onClick={onClickNext} />
    </div>
  );
}

Controls.propTypes = {
  onClickNext: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
};

export default Controls;
