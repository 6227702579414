import React from 'react';
import PropTypes from 'prop-types';

import Section from 'components/Section';
import BackgroundImage from 'components/BackgroundImage';

import classes from './HomeTitle.scss';

function HomeTitle({ image, alt, children }) {
  return (
    <div className={classes.root}>
      <BackgroundImage image={image} alt={alt}>
        <div className={classes.overlay}>
          <Section>{children}</Section>
        </div>
      </BackgroundImage>
    </div>
  );
}

HomeTitle.propTypes = {
  image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  alt: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default HomeTitle;
